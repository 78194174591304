import { CheckboxComponent } from '@components/atoms/checkbox';
import { DropdownButton } from '@components/atoms/form/dropdown-menu/dropdown-button';
import { Popover, PopoverContent, PopoverTrigger } from '@components/atoms/popover';
import Text from '@components/atoms/text';
import React, { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface Options {
  value: string;
  label: string;
}

interface FilterMultiSelectSectionProps {
  title: string;
  fieldName: string;
  options: Options[];
  placeholder: string;
  selectedOptions: string[];
  onChange: (selected: string[]) => void;
  rules?: object;
  className?: string;
}

const FilterMultiSelectSection: React.FC<FilterMultiSelectSectionProps> = ({
  title,
  placeholder,
  options,
  className = '',
  selectedOptions,
  onChange,
}) => {
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (triggerRef.current) {
      setMenuWidth(triggerRef.current.offsetWidth);
    }
  }, [triggerRef.current]);

  const handleOptionToggle = (value: string) => {
    const newSelection = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];
    onChange(newSelection);
  };

  const displayText =
    selectedOptions.length > 0
      ? options
          .filter((option) => selectedOptions.includes(option.value))
          .map((option) => option.label)
          .join(', ')
      : placeholder;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="mt-6">
      <div className="pt-4">
        <Text size="text-paragraph-medium" weight="font-bold">
          {title}
        </Text>
      </div>

      <div className={twMerge('mt-2', className)}>
        <Popover>
          <PopoverTrigger asChild>
            <DropdownButton ref={triggerRef} variant="select" size="sm" className="w-full h-10">
              {displayText}
            </DropdownButton>
          </PopoverTrigger>
          <PopoverContent
            align="end"
            className="overflow-y-auto bg-white rounded-lg border border-gray-200 shadow-md z-[9999] max-h-[200px]"
            style={{ width: menuWidth }}
          >
            {options.map((option) => (
              <CheckboxComponent.Root key={option.value}>
                <CheckboxComponent.Indicator
                  checked={selectedOptions.includes(option.value)}
                  onCheckedChange={() => handleOptionToggle(option.value)}
                  type="semi-rounded"
                />
                <CheckboxComponent.Label content={option.label} />
              </CheckboxComponent.Root>
            ))}
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default FilterMultiSelectSection;
