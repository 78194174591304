import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

import { VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';

import { useCreateProposal } from '@hooks/create-mcp-proposal/useCreateProposal';
import { EMPTY_PROPOSAL, ProposalPayload } from '@hooks/create-mcp-proposal/types';
import parsePayload from '@hooks/create-mcp-proposal/parser';
import { useCreateCommercialGroup } from '@hooks/create-commerical-group/useCommercialGorup';
import { CommercialGroupPayload } from '@hooks/create-commerical-group/types';

import Container from '@components/molecules/proposal-form';
import ToastComponent from '@components/atoms/toast/toast';

interface ProposalFormProps {
  traderId: string;
  isAdmin: boolean;
  setHeaderTitle: React.Dispatch<React.SetStateAction<'PURCHASE' | 'SALE'>>;
}

const CreateProposalForm = ({ traderId, isAdmin, setHeaderTitle }: ProposalFormProps) => {
  const [toastMessage, setToastMessage] = useState<string>('');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();

  const methods = useForm<ProposalPayload>({
    defaultValues: EMPTY_PROPOSAL,
    mode: 'all',
  });

  const contractType = methods.watch('contractType');

  useEffect(() => {
    if (contractType) setHeaderTitle(contractType as 'SALE' | 'PURCHASE');
  }, [contractType]);

  const { createGroupFn, loading: loadingCreateGroup } = useCreateCommercialGroup();
  const { createProposalFn, loading: loadingCreateProposal } = useCreateProposal();

  const onSubmit = async (values: any) => {
    setToastMessage('');
    try {
      const groupPayload: CommercialGroupPayload = {
        name: values.name,
        docType: 'CNPJ',
        legalName: values.legalName,
        docNumber: values.docNumber,
        type: 'MANAGEMENT_WHOLESALE',
      };
      const { data } = await createGroupFn({
        variables: { input: { ...groupPayload } },
      });
      const groupId = data?.createCommercialGroup?.id;
      if (groupId && traderId) {
        const parsedValues = parsePayload(values, groupId, traderId);
        await createProposalFn({ variables: { input: { ...parsedValues } } });

        setToastMessage('Cotação enviada com sucesso!');
        setTimeout(() => navigate(VIEW_PROPOSAL_LIST_PATH), 1000);
      }
    } catch (e) {
      setToastMessage('Erro ao enviar Cotação.');
      throw new Error(`Mutation error: ${e}`);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container
          adminMode={isAdmin}
          formType={contractType as 'SALE' | 'PURCHASE'}
          buttons={[
            {
              label: 'Enviar Cotação',
              kind: 'primary',
              type: 'submit',
              size: '1',
              loading: loadingCreateGroup || loadingCreateProposal,
            },
            {
              label: 'Cancelar',
              kind: 'secondary',
              size: '1',
              onClick: () => navigate(VIEW_PROPOSAL_LIST_PATH),
            },
          ]}
        />
      </form>
      <ToastComponent label={toastMessage} type={toastMessage.length > 0 ? true : false} />
    </FormProvider>
  );
};

export default CreateProposalForm;
